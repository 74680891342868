// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Generic classes */
/*Application Width*/
.cw {
  width: 1200px;
}
.oh {
  overflow: hidden;
}
.dn {
  display: none !important;
}
.fl {
  float: left;
}

.fr {
  float: right;
}

.center {
  margin: 0 auto;
}

.tr {
  text-align: right;
}

.tl {
  text-align: left;
}

.form-hidden {
  display: none;
}

.bs-mild {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
}
`, "",{"version":3,"sources":["webpack://./src/style/common.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB,oBAAoB;AACpB;EACE,aAAa;AACf;AACA;EACE,gBAAgB;AAClB;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":["/* Generic classes */\n/*Application Width*/\n.cw {\n  width: 1200px;\n}\n.oh {\n  overflow: hidden;\n}\n.dn {\n  display: none !important;\n}\n.fl {\n  float: left;\n}\n\n.fr {\n  float: right;\n}\n\n.center {\n  margin: 0 auto;\n}\n\n.tr {\n  text-align: right;\n}\n\n.tl {\n  text-align: left;\n}\n\n.form-hidden {\n  display: none;\n}\n\n.bs-mild {\n  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
