// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payload-preview > pre {
  width: 100%;
  background-color: #f5f5f5;
  padding: 20px;
  line-height: 1.3;
}
`, "",{"version":3,"sources":["webpack://./src/modules/integrations/CustomWebhook.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".payload-preview > pre {\n  width: 100%;\n  background-color: #f5f5f5;\n  padding: 20px;\n  line-height: 1.3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
