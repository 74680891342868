// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Elements */
.elementInput > div {
    width: 50%;
  }
  .elementPassword input {
    background-color: #f5f5f5;
    color: #a0a0a0;
    width: 100%;
    padding: 5px 16px;
    height: 30px;
    border: 0;
    outline: 0;
  }
  `, "",{"version":3,"sources":["webpack://./src/modules/internal/Password.css"],"names":[],"mappings":"AAAA,aAAa;AACb;IACI,UAAU;EACZ;EACA;IACE,yBAAyB;IACzB,cAAc;IACd,WAAW;IACX,iBAAiB;IACjB,YAAY;IACZ,SAAS;IACT,UAAU;EACZ","sourcesContent":["/* Elements */\n.elementInput > div {\n    width: 50%;\n  }\n  .elementPassword input {\n    background-color: #f5f5f5;\n    color: #a0a0a0;\n    width: 100%;\n    padding: 5px 16px;\n    height: 30px;\n    border: 0;\n    outline: 0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
