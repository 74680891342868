// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectedrole {
  background-color: #1c5c85;
  color: #ffffff;
}

.rolewrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 30px;
  background-color: #f5f5f5;
}

.rolelist {
  grid-column: span 1;
  display: inline-block;
  width: 300px;
  height: 600px;
  overflow: scroll;
}

.role,
.selectedrole {
  padding: 10px;
  margin: 5px;
  border-radius: 8px;
  color: #ffffff;
}

.role {
  background-color: #e2e2e2;
  color: #333333;
}

.role:hover {
  cursor: pointer;
  background-color: #8A00D4;
}
.rolepands {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  grid-column: span 3;
}

.rolepermission {
  grid-column: span 1;
}

.savedornot {
  grid-column: span 1;
  padding: 0px 10px;
}

.servermessage {
  grid-column: span 1;
  padding: 0px 10px;
}

.rolewrap .elementTextBox {
  padding: 30px 10px 30px 10px;
}

.rolewrap .elementNumberE {
  padding: 10px;
}

.rolewrap .elementCheckbox {
  padding: 5px 10px;
}

.rolewrap .elementButton {
  padding: 10px;
}

.rolewrap .elementButton input {
  background-color: #e2e2e2 !important;
}
`, "",{"version":3,"sources":["webpack://./src/modules/admin/Roles.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sCAAsC;EACtC,cAAc;EACd,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB;;AAEA;;EAEE,aAAa;EACb,WAAW;EACX,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,0BAA0B;EAC1B,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".selectedrole {\n  background-color: #1c5c85;\n  color: #ffffff;\n}\n\n.rolewrap {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  grid-gap: 20px;\n  padding: 30px;\n  background-color: #f5f5f5;\n}\n\n.rolelist {\n  grid-column: span 1;\n  display: inline-block;\n  width: 300px;\n  height: 600px;\n  overflow: scroll;\n}\n\n.role,\n.selectedrole {\n  padding: 10px;\n  margin: 5px;\n  border-radius: 8px;\n  color: #ffffff;\n}\n\n.role {\n  background-color: #e2e2e2;\n  color: #333333;\n}\n\n.role:hover {\n  cursor: pointer;\n  background-color: #8A00D4;\n}\n.rolepands {\n  display: grid;\n  grid-template-columns: 1fr;\n  grid-gap: 20px;\n  grid-column: span 3;\n}\n\n.rolepermission {\n  grid-column: span 1;\n}\n\n.savedornot {\n  grid-column: span 1;\n  padding: 0px 10px;\n}\n\n.servermessage {\n  grid-column: span 1;\n  padding: 0px 10px;\n}\n\n.rolewrap .elementTextBox {\n  padding: 30px 10px 30px 10px;\n}\n\n.rolewrap .elementNumberE {\n  padding: 10px;\n}\n\n.rolewrap .elementCheckbox {\n  padding: 5px 10px;\n}\n\n.rolewrap .elementButton {\n  padding: 10px;\n}\n\n.rolewrap .elementButton input {\n  background-color: #e2e2e2 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
