import React from 'react'
import './Footer.css'

export default function Footer() {
  const YEAR = new Date().getFullYear()
  return (
    // <footer className="footer cw center grid">
    //   <div className="col-16-16 text-center">Copyright © {YEAR} CareEngine.com.au</div>
      
    // </footer>
    <></>
  )
}
