// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `iframe {
  width: 100%;
  border: none;
}

.preview-form-wrapper {
  background: #f5f5f5;
  z-index: 9999;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 86px;
  left: 0;
  animation: 1s ease-out 0.7s 1 slideUp forwards;
}

.preview-warning-navback-button {
  float: left;
  border: none;
  cursor: pointer;
  margin-top: 18px;
  margin-left: 10px;
  background: transparent;
  color: #ffffff;
  font-family: Montserrat;
}

.preview-warning-text {
  padding: 0 85px 0 85px;
}

.preview-warning-wrapper {
  background: #113952;
  color: white;
  text-align: center;
  padding: 4px;
}

.iframe-wrapper {
  width: 100%;
  height: calc(100% - 70px);
}

@keyframes slideUp {
  0% {
    top: 86px;
  }
  100% {
    top: 5px;
  }
}

html,
body,
iframe {
  min-height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/modules/helper/PreviewForm.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,YAAY;EACZ,eAAe;EACf,SAAS;EACT,OAAO;EACP,8CAA8C;AAChD;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;EACvB,cAAc;EACd,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE;IACE,SAAS;EACX;EACA;IACE,QAAQ;EACV;AACF;;AAEA;;;EAGE,gBAAgB;AAClB","sourcesContent":["iframe {\n  width: 100%;\n  border: none;\n}\n\n.preview-form-wrapper {\n  background: #f5f5f5;\n  z-index: 9999;\n  width: 100%;\n  height: 100%;\n  position: fixed;\n  top: 86px;\n  left: 0;\n  animation: 1s ease-out 0.7s 1 slideUp forwards;\n}\n\n.preview-warning-navback-button {\n  float: left;\n  border: none;\n  cursor: pointer;\n  margin-top: 18px;\n  margin-left: 10px;\n  background: transparent;\n  color: #ffffff;\n  font-family: Montserrat;\n}\n\n.preview-warning-text {\n  padding: 0 85px 0 85px;\n}\n\n.preview-warning-wrapper {\n  background: #113952;\n  color: white;\n  text-align: center;\n  padding: 4px;\n}\n\n.iframe-wrapper {\n  width: 100%;\n  height: calc(100% - 70px);\n}\n\n@keyframes slideUp {\n  0% {\n    top: 86px;\n  }\n  100% {\n    top: 5px;\n  }\n}\n\nhtml,\nbody,\niframe {\n  min-height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
