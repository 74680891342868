// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.elementNetPromoterScore ul {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  padding: 0 30px;
}

.elementNetPromoterScore ul li {
  display: inline-flex;
}

.elementNetPromoterScore .scale-legend .sublabel {
  text-align: center;
}

.elementNetPromoterScore [type='radio'] {
  display: none;
}

.elementNetPromoterScore .net-promoter-score-label {
  display: inline-block;
  border: 1px solid #1c5c85;
  background-color: #fff;
  color: #1c5c85;
  width: 40px;
  height: 30px;
  margin: 0 5px;
  -webkit-user-select: none;
          user-select: none;
  text-align: center;
  line-height: 28px;
}

.elementNetPromoterScore .net-promoter-score-label:hover {
  border: 1px solid #1c5c85;
  background-color: #1c5c85;
  color: #fff;
}

.elementNetPromoterScore input[type='radio']:checked + label {
  border: 1px solid #1c5c85;
  background-color: #1c5c85;
  color: #fff;
}
`, "",{"version":3,"sources":["webpack://./src/modules/elements/NetPromoterScore.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;EACb,6BAA6B;EAC7B,eAAe;AACjB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,sBAAsB;EACtB,cAAc;EACd,WAAW;EACX,YAAY;EACZ,aAAa;EACb,yBAAiB;UAAjB,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,WAAW;AACb","sourcesContent":[".elementNetPromoterScore ul {\n  list-style: none;\n  display: flex;\n  justify-content: space-evenly;\n  padding: 0 30px;\n}\n\n.elementNetPromoterScore ul li {\n  display: inline-flex;\n}\n\n.elementNetPromoterScore .scale-legend .sublabel {\n  text-align: center;\n}\n\n.elementNetPromoterScore [type='radio'] {\n  display: none;\n}\n\n.elementNetPromoterScore .net-promoter-score-label {\n  display: inline-block;\n  border: 1px solid #1c5c85;\n  background-color: #fff;\n  color: #1c5c85;\n  width: 40px;\n  height: 30px;\n  margin: 0 5px;\n  user-select: none;\n  text-align: center;\n  line-height: 28px;\n}\n\n.elementNetPromoterScore .net-promoter-score-label:hover {\n  border: 1px solid #1c5c85;\n  background-color: #1c5c85;\n  color: #fff;\n}\n\n.elementNetPromoterScore input[type='radio']:checked + label {\n  border: 1px solid #1c5c85;\n  background-color: #1c5c85;\n  color: #fff;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
