// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table.fp_table {
  border-collapse: collapse;
  width: 100%;
}
table.fp_table tr,
table.fp_table td,
table.fp_table th {
  margin: 0;
  padding: 0;
  border: none;
}

table.fp_table > thead {
  background-color: #e2e2e2;
  color: #646464;
  height: 30px;
  font-size: 10px;
  text-align: left;
  font-weight: normal;
  letter-spacing: 0;
}

table.fp_table > tbody > tr > td.mw,
table.fp_table > thead > tr > th.mw {
  min-width: 20px;
}

table.fp_table > tbody > tr > td.text_center,
table.fp_table > thead > tr > th.text_center {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/modules/common/Table.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;AACb;AACA;;;EAGE,SAAS;EACT,UAAU;EACV,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;;EAEE,eAAe;AACjB;;AAEA;;EAEE,kBAAkB;AACpB","sourcesContent":["table.fp_table {\n  border-collapse: collapse;\n  width: 100%;\n}\ntable.fp_table tr,\ntable.fp_table td,\ntable.fp_table th {\n  margin: 0;\n  padding: 0;\n  border: none;\n}\n\ntable.fp_table > thead {\n  background-color: #e2e2e2;\n  color: #646464;\n  height: 30px;\n  font-size: 10px;\n  text-align: left;\n  font-weight: normal;\n  letter-spacing: 0;\n}\n\ntable.fp_table > tbody > tr > td.mw,\ntable.fp_table > thead > tr > th.mw {\n  min-width: 20px;\n}\n\ntable.fp_table > tbody > tr > td.text_center,\ntable.fp_table > thead > tr > th.text_center {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
