import React from 'react'

export default function Logo(props) {
  return (
    <svg width="48" height="48" viewBox="0 0 146 160" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.883789 51.0497V108.953C0.883789 117.158 5.20982 124.758 12.2716 128.958L58.9383 156.717C66.298 161.094 75.4696 161.094 82.8293 156.717L129.496 128.958C136.558 124.758 140.884 117.158 140.884 108.953V51.2941C140.884 43.1409 136.612 35.5814 129.621 31.3638L83.1994 3.35806C75.8387 -1.08261 66.6271 -1.12166 59.2289 3.25644L12.3169 31.0177C5.22946 35.2119 0.883789 42.826 0.883789 51.0497Z" fill="#8A00D4"/>
<g filter="url(#filter0_d_5_200)">
<path d="M106.296 17L133.227 33.2351C138.123 36.1868 141.116 41.4799 141.116 47.1891V112.688C141.116 118.434 138.085 123.755 133.14 126.695L106.116 142.757L106.256 110.247C106.281 104.441 103.21 99.0604 98.1946 96.1201L74.0354 81.9575C72.5088 81.0626 72.4978 78.8634 74.0153 77.9533L98.2351 63.4269C103.128 60.4924 106.131 55.2214 106.155 49.5243L106.296 17Z" fill="#F12796"/>
<path d="M106.655 49.5265L106.792 17.883L132.969 33.6633C137.715 36.5247 140.616 41.6555 140.616 47.1891V112.688C140.616 118.257 137.679 123.415 132.884 126.265L106.619 141.876L106.756 110.249C106.781 104.265 103.617 98.719 98.4474 95.6888L74.2883 81.5262C73.0893 80.8233 73.0806 79.0969 74.2724 78.3821L98.4923 63.8557C103.535 60.8314 106.63 55.3988 106.655 49.5265Z" stroke="#F12796"/>
</g>
<path fill-rule="evenodd" clip-rule="evenodd" d="M105.884 142.861V110.172C105.884 104.446 102.874 99.1397 97.9539 96.1936L75.8858 82.9782C73.6201 81.6215 73.6201 78.3442 75.8858 76.9874L97.9539 63.772C102.874 60.8259 105.884 55.5195 105.884 49.7933V17.1042L43.8137 54.2745C38.894 57.2206 35.8838 62.527 35.8838 68.2532V91.7124C35.8838 97.4386 38.894 102.745 43.8137 105.691L105.884 142.861Z" fill="white"/>
<defs>
<filter id="filter0_d_5_200" x="68.8838" y="17" width="76.2314" height="133.757" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5_200"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5_200" result="shape"/>
</filter>
</defs>
</svg>

  )
}
