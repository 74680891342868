// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wlwrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  padding: 30px;
  background-color: #f5f5f5;
}

.wllist {
  grid-column: span 1;
  display: inline-block;
  width: 300px;
  height: 600px;
  overflow: scroll;
}

.wl {
  padding: 10px;
  margin: 5px;
  border-radius: 8px;
  color: #ffffff;
  background-color: #e2e2e2;
  color: #333333;
}
`, "",{"version":3,"sources":["webpack://./src/modules/admin/Whitelist.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sCAAsC;EACtC,cAAc;EACd,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,kBAAkB;EAClB,cAAc;EACd,yBAAyB;EACzB,cAAc;AAChB","sourcesContent":[".wlwrap {\n  display: grid;\n  grid-template-columns: 1fr 1fr 1fr 1fr;\n  grid-gap: 20px;\n  padding: 30px;\n  background-color: #f5f5f5;\n}\n\n.wllist {\n  grid-column: span 1;\n  display: inline-block;\n  width: 300px;\n  height: 600px;\n  overflow: scroll;\n}\n\n.wl {\n  padding: 10px;\n  margin: 5px;\n  border-radius: 8px;\n  color: #ffffff;\n  background-color: #e2e2e2;\n  color: #333333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
