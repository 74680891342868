// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.address-section > input,
.address-section > select {
  height: 2.3rem;
  border-radius: 3px;
  background-color: #f5f5f5;
  border: solid 1px #c9c9c9;
  opacity: 1;
}

.address-section {
  display: inline-block;
  float: left;
  margin: 0 0 10px 0;
}

.form.platform-win .elementAddress {
  font-family: 'Twemoji Country Flags', 'Montserrat', sans-serif !important;
}

.infernal.form .elementAddress {
  font-family: 'Twemoji Country Flags', 'Montserrat', sans-serif !important;
}

.address-section.adr-street {
  width: 100%;
  padding: 0 2px 0 2px;
}

.address-section > input {
  background-color: #f5f5f5;
  border: solid 1px #c9c9c9;
  height: 2.3rem;
  border-radius: 3px;
}

.adr-street > input {
  width: 99%;
}

.adr-state,
.adr-country,
.adr-city,
.adr-zip {
  padding: 0 2px 0 2px;
}

.adr-state > input,
.adr-state > select,
.adr-country > select,
.adr-zip > input,
.adr-city > input {
  width: 100%;
}

.address-flex-row {
  display: flex;
  clear: both;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/modules/elements/Address.css"],"names":[],"mappings":"AAAA;;EAEE,cAAc;EACd,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,yEAAyE;AAC3E;;AAEA;EACE,yEAAyE;AAC3E;;AAEA;EACE,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,yBAAyB;EACzB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;;;;EAIE,oBAAoB;AACtB;;AAEA;;;;;EAKE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;EACX,8BAA8B;AAChC","sourcesContent":[".address-section > input,\n.address-section > select {\n  height: 2.3rem;\n  border-radius: 3px;\n  background-color: #f5f5f5;\n  border: solid 1px #c9c9c9;\n  opacity: 1;\n}\n\n.address-section {\n  display: inline-block;\n  float: left;\n  margin: 0 0 10px 0;\n}\n\n.form.platform-win .elementAddress {\n  font-family: 'Twemoji Country Flags', 'Montserrat', sans-serif !important;\n}\n\n.infernal.form .elementAddress {\n  font-family: 'Twemoji Country Flags', 'Montserrat', sans-serif !important;\n}\n\n.address-section.adr-street {\n  width: 100%;\n  padding: 0 2px 0 2px;\n}\n\n.address-section > input {\n  background-color: #f5f5f5;\n  border: solid 1px #c9c9c9;\n  height: 2.3rem;\n  border-radius: 3px;\n}\n\n.adr-street > input {\n  width: 99%;\n}\n\n.adr-state,\n.adr-country,\n.adr-city,\n.adr-zip {\n  padding: 0 2px 0 2px;\n}\n\n.adr-state > input,\n.adr-state > select,\n.adr-country > select,\n.adr-zip > input,\n.adr-city > input {\n  width: 100%;\n}\n\n.address-flex-row {\n  display: flex;\n  clear: both;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
