import * as React from 'react'

const SubmitSuccess = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={349} height={302} {...props}>
    <defs>
      <style>
        {
          '.prefix__cls-6{fill:#fff}.prefix__cls-10,.prefix__cls-3{fill:#8A00D4}.prefix__cls-3{opacity:.4}.prefix__cls-4{fill:#8A00D4}.prefix__cls-5{fill:#7aad37}.prefix__cls-8{fill:#0071bc}.prefix__cls-9{fill:#1c5c85}.prefix__cls-11{fill:#00538a}.prefix__cls-14{fill:#e2e2e2}.prefix__cls-15{fill:#0c2738}.prefix__cls-17{fill:#fffcdd}'
        }
      </style>
    </defs>
    <g
      id="prefix__Group_194"
      data-name="Group 194"
      transform="translate(-9179.586 -258.203)">
      <g
        id="prefix__Group_193"
        data-name="Group 193"
        transform="translate(9195.504 276.982)">
        <path
          id="prefix__Path_124"
          d="M340.609 195.123c-3.111-13.492-13.968-24.554-18.281-37.956-9.85-30.6 18.106-70.348-15.341-95.005-33.967-25.04-73.878 5.968-89.027 11.8-28.836 11.092-51.768 1.482-68.638-1.688-27.77-5.219-56.986 1.868-79.571 17.519-46.06 31.919-51.742 91.986-40.458 117.36 6.568 12.935 13.358 18.785 22.414 24.5 38.291 24.17 78.309-19.565 121.84-11.428 23.605 4.412 34.076 21.9 55.25 31.505 59.581 27.031 119.555-23.03 111.812-56.607z"
          data-name="Path 124"
          transform="translate(-24.317 -52.527)"
          style={{
            opacity: 0.2,
            fill: '#8A00D4'
          }}
        />
        <ellipse
          id="prefix__Ellipse_25"
          cx={91.825}
          cy={8.91}
          className="prefix__cls-3"
          data-name="Ellipse 25"
          rx={91.825}
          ry={8.91}
          transform="translate(119.66 232.403)"
        />
        <g
          id="prefix__Group_173"
          data-name="Group 173"
          transform="translate(134.32 10.212)">
          <path
            id="prefix__Path_125"
            d="M353.829 305.51H231.137a15.811 15.811 0 0 1-15.819-15.818V103.578a15.817 15.817 0 0 1 15.819-15.819h122.692a15.81 15.81 0 0 1 15.819 15.819v186.114a15.814 15.814 0 0 1-15.819 15.818z"
            className="prefix__cls-4"
            data-name="Path 125"
            transform="translate(-215.318 -73.194)"
          />
          <path
            id="prefix__Path_126"
            d="M220.639 93.429a15.981 15.981 0 0 0-1.84 1.931 15.753 15.753 0 0 0-3.48 9.9v186.115a15.812 15.812 0 0 0 15.819 15.818h122.691a15.761 15.761 0 0 0 10.976-4.435C343.6 272.037 258.287 148.436 220.639 93.429z"
            className="prefix__cls-5"
            data-name="Path 126"
            transform="translate(-215.318 -74.877)"
          />
          <path
            id="prefix__Rectangle_370"
            d="M0 0h131.777v192.444H0z"
            className="prefix__cls-6"
            data-name="Rectangle 370"
            transform="translate(11.277 27.219)"
          />
          <path
            id="prefix__Path_127"
            d="M363.13 298.2H231.353V105.753z"
            data-name="Path 127"
            transform="translate(-220.077 -78.534)"
            style={{
              opacity: 0.1,
              fill: '#0071bc'
            }}
          />
          <path
            id="prefix__Path_128"
            d="M329.779 75.284a2.461 2.461 0 0 1-2.4-1.894 8.246 8.246 0 0 0-8.022-6.342h-11.574a8.245 8.245 0 0 0-8.021 6.342 2.461 2.461 0 0 1-2.4 1.894h-8.515a2.469 2.469 0 0 0-2.469 2.469v18.272a2.469 2.469 0 0 0 2.469 2.469h49.444a2.469 2.469 0 0 0 2.469-2.469V77.753a2.469 2.469 0 0 0-2.469-2.469z"
            className="prefix__cls-8"
            data-name="Path 128"
            transform="translate(-236.406 -67.048)"
          />
          <path
            id="prefix__Path_129"
            d="M322.681 93.31a38.981 38.981 0 0 1-30.407-14.551h-3.426a2.469 2.469 0 0 0-2.469 2.469V99.5a2.469 2.469 0 0 0 2.469 2.469h49.444a2.469 2.469 0 0 0 2.469-2.469V88.872a38.876 38.876 0 0 1-18.08 4.438z"
            className="prefix__cls-9"
            data-name="Path 129"
            transform="translate(-236.406 -70.523)"
          />
        </g>
        <g
          id="prefix__Group_186"
          data-name="Group 186"
          transform="translate(159.758 65.683)">
          <g id="prefix__Group_177" data-name="Group 177">
            <g
              id="prefix__Group_176"
              data-name="Group 176"
              transform="translate(0 .119)">
              <g id="prefix__Group_174" data-name="Group 174">
                <path
                  id="prefix__Path_130"
                  d="M282.974 177.579h-31.483V146.1h31.483zm-29.373-2.11h27.263v-27.262H253.6z"
                  className="prefix__cls-10"
                  data-name="Path 130"
                  transform="translate(-251.491 -146.097)"
                />
              </g>
              <g
                id="prefix__Group_175"
                data-name="Group 175"
                transform="translate(38.728)">
                <path
                  id="prefix__Path_131"
                  d="M368.93 148.291h-61.271a1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1h61.271a1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.1z"
                  className="prefix__cls-10"
                  data-name="Path 131"
                  transform="translate(-306.561 -146.097)"
                />
                <path
                  id="prefix__Path_132"
                  d="M368.93 158.7h-61.271a1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1h61.271a1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.1z"
                  className="prefix__cls-10"
                  data-name="Path 132"
                  transform="translate(-306.561 -149.187)"
                />
                <path
                  id="prefix__Path_133"
                  d="M368.93 169.115h-61.271a1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1h61.271a1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.1z"
                  className="prefix__cls-10"
                  data-name="Path 133"
                  transform="translate(-306.561 -152.277)"
                />
                <path
                  id="prefix__Path_134"
                  d="M368.93 179.527h-61.271a1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1h61.271a1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.1z"
                  className="prefix__cls-10"
                  data-name="Path 134"
                  transform="translate(-306.561 -155.366)"
                />
                <path
                  id="prefix__Path_135"
                  d="M353.545 189.938h-45.886a1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1h45.886a1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.1z"
                  className="prefix__cls-10"
                  data-name="Path 135"
                  transform="translate(-306.561 -158.456)"
                />
              </g>
            </g>
            <path
              id="prefix__Path_136"
              d="m258.22 158.879 8.669 11.869 22.987-24.821-22.987 16.4-4.415-7.322z"
              className="prefix__cls-10"
              data-name="Path 136"
              transform="translate(-253.488 -145.927)"
            />
          </g>
          <g
            id="prefix__Group_181"
            data-name="Group 181"
            transform="translate(0 53.518)">
            <g
              id="prefix__Group_180"
              data-name="Group 180"
              transform="translate(0 .119)">
              <g id="prefix__Group_178" data-name="Group 178">
                <path
                  id="prefix__Path_137"
                  d="M282.974 253.681h-31.483V222.2h31.483zm-29.373-2.11h27.263v-27.262H253.6z"
                  className="prefix__cls-4"
                  data-name="Path 137"
                  transform="translate(-251.491 -222.199)"
                />
              </g>
              <g
                id="prefix__Group_179"
                data-name="Group 179"
                transform="translate(38.728)">
                <path
                  id="prefix__Path_138"
                  d="M368.93 224.393h-61.271a1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1h61.271a1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.1z"
                  className="prefix__cls-4"
                  data-name="Path 138"
                  transform="translate(-306.561 -222.199)"
                />
                <path
                  id="prefix__Path_139"
                  d="M368.93 234.805h-61.271a1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1h61.271a1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.1z"
                  className="prefix__cls-4"
                  data-name="Path 139"
                  transform="translate(-306.561 -225.289)"
                />
                <path
                  id="prefix__Path_140"
                  d="M368.93 245.217h-61.271a1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1h61.271a1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.1z"
                  className="prefix__cls-4"
                  data-name="Path 140"
                  transform="translate(-306.561 -228.378)"
                />
                <path
                  id="prefix__Path_141"
                  d="M368.93 255.629h-61.271a1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1h61.271a1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.1z"
                  className="prefix__cls-4"
                  data-name="Path 141"
                  transform="translate(-306.561 -231.468)"
                />
                <path
                  id="prefix__Path_142"
                  d="M353.545 266.04h-45.886a1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1h45.886a1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.1z"
                  className="prefix__cls-4"
                  data-name="Path 142"
                  transform="translate(-306.561 -234.558)"
                />
              </g>
            </g>
            <path
              id="prefix__Path_143"
              d="m258.22 234.981 8.669 11.869 22.987-24.821-22.987 16.4-4.415-7.322z"
              className="prefix__cls-4"
              data-name="Path 143"
              transform="translate(-253.488 -222.029)"
            />
          </g>
          <g
            id="prefix__Group_185"
            data-name="Group 185"
            transform="translate(0 107.036)">
            <g
              id="prefix__Group_184"
              data-name="Group 184"
              transform="translate(0 .119)">
              <g id="prefix__Group_182" data-name="Group 182">
                <path
                  id="prefix__Path_144"
                  d="M282.974 329.783h-31.483V298.3h31.483zm-29.373-2.11h27.263v-27.262H253.6z"
                  className="prefix__cls-8"
                  data-name="Path 144"
                  transform="translate(-251.491 -298.301)"
                />
              </g>
              <g
                id="prefix__Group_183"
                data-name="Group 183"
                transform="translate(38.728)">
                <path
                  id="prefix__Path_145"
                  d="M368.93 300.5h-61.271a1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1h61.271a1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.1z"
                  className="prefix__cls-8"
                  data-name="Path 145"
                  transform="translate(-306.561 -298.301)"
                />
                <path
                  id="prefix__Path_146"
                  d="M368.93 310.907h-61.271a1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1h61.271a1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.1z"
                  className="prefix__cls-8"
                  data-name="Path 146"
                  transform="translate(-306.561 -301.391)"
                />
                <path
                  id="prefix__Path_147"
                  d="M368.93 321.319h-61.271a1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1h61.271a1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.1z"
                  className="prefix__cls-8"
                  data-name="Path 147"
                  transform="translate(-306.561 -304.48)"
                />
                <path
                  id="prefix__Path_148"
                  d="M368.93 331.73h-61.271a1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1h61.271a1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.1z"
                  className="prefix__cls-8"
                  data-name="Path 148"
                  transform="translate(-306.561 -307.57)"
                />
                <path
                  id="prefix__Path_149"
                  d="M353.545 342.142h-45.886a1.1 1.1 0 0 1-1.1-1.1 1.1 1.1 0 0 1 1.1-1.1h45.886a1.1 1.1 0 0 1 1.1 1.1 1.1 1.1 0 0 1-1.1 1.1z"
                  className="prefix__cls-8"
                  data-name="Path 149"
                  transform="translate(-306.561 -310.66)"
                />
              </g>
            </g>
            <path
              id="prefix__Path_150"
              d="m258.22 311.083 8.669 11.869 22.987-24.821-22.987 16.4-4.415-7.322z"
              className="prefix__cls-8"
              data-name="Path 150"
              transform="translate(-253.488 -298.131)"
            />
          </g>
        </g>
        <g
          id="prefix__Group_192"
          data-name="Group 192"
          transform="translate(14.11 73.205)">
          <ellipse
            id="prefix__Ellipse_26"
            cx={53.305}
            cy={4.597}
            className="prefix__cls-3"
            data-name="Ellipse 26"
            rx={53.305}
            ry={4.597}
            transform="translate(21.495 181.578)"
          />
          <g id="prefix__Group_189" data-name="Group 189">
            <path
              id="prefix__Path_151"
              d="m50.7 258.707 16.864-8.421-5-10.013-16.864 8.422a2.381 2.381 0 0 0-1.195 1.371 2.353 2.353 0 0 0 .124 1.807l2.886 5.781a2.351 2.351 0 0 0 1.37 1.185 2.379 2.379 0 0 0 1.815-.132z"
              className="prefix__cls-9"
              data-name="Path 151"
              transform="translate(-44.381 -181.447)"
            />
            <path
              id="prefix__Path_152"
              d="m60.438 241.931 3.63 7.27-15.675 7.826.313.627a2.351 2.351 0 0 0 1.37 1.185 2.379 2.379 0 0 0 1.814-.131l16.864-8.421-5-10.013z"
              className="prefix__cls-11"
              data-name="Path 152"
              transform="translate(-45.572 -181.447)"
            />
            <path
              id="prefix__Path_153"
              d="m64.247 251.943-3.63-7.27-15.675 7.827-.312-.626a2.353 2.353 0 0 1-.124-1.807 2.381 2.381 0 0 1 1.195-1.371l16.864-8.421 5 10.013z"
              data-name="Path 153"
              transform="translate(-44.381 -181.447)"
              style={{
                fill: '#113952'
              }}
            />
            <path
              id="prefix__Path_154"
              d="m186.036 170.293-122.7 61.014a2.34 2.34 0 0 1-1.748.141 2.311 2.311 0 0 1-1.385-1.177l-4.437-8.888a2.31 2.31 0 0 1-.122-1.778 2.337 2.337 0 0 1 1.175-1.348l122.7-61.014z"
              className="prefix__cls-8"
              data-name="Path 154"
              transform="translate(-47.687 -156.807)"
            />
            <path
              id="prefix__Path_155"
              d="m231.846 157.059 17.2-.436-10.683 13.486z"
              data-name="Path 155"
              transform="translate(-100.013 -156.623)"
              style={{
                fill: '#c9c9c9'
              }}
            />
            <path
              id="prefix__Path_156"
              d="m238.6 166.565 12.452-9.942-10.683 13.486z"
              className="prefix__cls-14"
              data-name="Path 156"
              transform="translate(-102.016 -156.623)"
            />
            <path
              id="prefix__Path_157"
              d="m231.846 157.059 17.2-.436-15.429 3.98z"
              className="prefix__cls-14"
              data-name="Path 157"
              transform="translate(-100.013 -156.623)"
            />
            <path
              id="prefix__Path_158"
              d="m249.68 156.741 1.764 3.534 2.893-3.652z"
              className="prefix__cls-10"
              data-name="Path 158"
              transform="translate(-105.305 -156.623)"
            />
            <path
              id="prefix__Path_159"
              d="m61.14 232.819.73 1.463a2.311 2.311 0 0 0 1.385 1.177 2.34 2.34 0 0 0 1.745-.14L187.7 174.3l-1.769-3.544z"
              className="prefix__cls-15"
              data-name="Path 159"
              transform="translate(-49.355 -160.819)"
            />
            <path
              id="prefix__Path_160"
              d="m56.495 222.846-.73-1.463a2.315 2.315 0 0 1-.11-1.814 2.338 2.338 0 0 1 1.163-1.312l122.7-61.014 1.769 3.544z"
              className="prefix__cls-9"
              data-name="Path 160"
              transform="translate(-47.687 -156.807)"
            />
            <path
              id="prefix__Rectangle_371"
              d="M0 0h3.982v3.961H0z"
              className="prefix__cls-10"
              data-name="Rectangle 371"
              transform="rotate(-26.54 133.056 3.992)"
            />
            <path
              id="prefix__Rectangle_372"
              d="M0 0h3.982v3.961H0z"
              className="prefix__cls-10"
              data-name="Rectangle 372"
              transform="rotate(-26.54 155.574 -1.315)"
            />
            <path
              id="prefix__Rectangle_373"
              d="M0 0h3.982v6.664H0z"
              className="prefix__cls-10"
              data-name="Rectangle 373"
              transform="rotate(-26.533 141.542 2.007)"
            />
          </g>
          <g
            id="prefix__Group_191"
            data-name="Group 191"
            transform="translate(19.612 1.973)">
            <path
              id="prefix__Path_161"
              d="m139.051 285.025-8.338 11.124s7.573 5.485 15.415 2.178 23.6-2.7 23.988-8.178-15.663-8.669-15.663-8.669z"
              className="prefix__cls-15"
              data-name="Path 161"
              transform="translate(-89.613 -195.649)"
            />
            <path
              id="prefix__Path_162"
              d="M166.939 412.629s9.742.3 9.983 3.085-4.718 5.014-10.943 4.871c-5.654-.13-10.421-.331-10.314-2.028s.914-5.928.914-5.928z"
              className="prefix__cls-4"
              data-name="Path 162"
              transform="translate(-97.017 -234.568)"
            />
            <path
              id="prefix__Path_163"
              d="M166.939 412.629h-10.36s-.807 4.23-.914 5.928 4.66 1.9 10.314 2.028c4.82.111 8.869-1.2 10.345-3.094-2.314-3.42-9.385-4.862-9.385-4.862z"
              className="prefix__cls-9"
              data-name="Path 163"
              transform="translate(-97.017 -234.568)"
            />
            <path
              id="prefix__Path_164"
              d="M123.311 400.768s5.877 9.783 3.772 13.632-10.936-5.52-12.874-9.66c-2.482-5.3-2.372-5.785 3.942-11.945 2.364-2.307 2.779-1.436 2.779-1.436s1.3 3.032 1.968 3.288.413 6.121.413 6.121z"
              className="prefix__cls-4"
              data-name="Path 164"
              transform="translate(-84.268 -228.208)"
            />
            <path
              id="prefix__Path_165"
              d="M127.082 414.4a3.463 3.463 0 0 0 .328-.9c-.853-4.852-6.222-11.911-7.291-14.061a3.592 3.592 0 0 1 .938-4.172l1.207-1.305a21.163 21.163 0 0 1-1.335-2.6s-.414-.871-2.779 1.436c-6.314 6.159-6.425 6.64-3.942 11.945 1.939 4.136 10.769 13.504 12.874 9.657z"
              className="prefix__cls-9"
              data-name="Path 165"
              transform="translate(-84.268 -228.208)"
            />
            <path
              id="prefix__Path_166"
              d="M113.139 404.618a1.625 1.625 0 0 0-.239.506 18.979 18.979 0 0 0 1.369 3.466c1.7 3.634 8.715 11.3 11.826 10.509a9.473 9.473 0 0 0-1.709-5.071c-2.757-4.228-9.557-11.768-11.247-9.41z"
              className="prefix__cls-15"
              data-name="Path 166"
              transform="translate(-84.327 -232.058)"
            />
            <path
              id="prefix__Path_167"
              d="M136.108 290.334s.182.507.507 1.449c3.117 9.035 19.381 58.075 14.161 82.957 0 0 6.918.766 10.36 0 0 0 4.266-38.6-2.835-60.355-4.516-13.836-8.735-29.434-8.735-29.434"
              className="prefix__cls-15"
              data-name="Path 167"
              transform="translate(-91.214 -196.678)"
            />
            <path
              id="prefix__Path_168"
              d="M164.479 282.792s4.309 47.092-38.868 83.183a10.577 10.577 0 0 0-5.159-7.975s29-42.521 25.808-52.9-11.06-14.175-10.255-22.308 14.892-8.838 14.892-8.838z"
              className="prefix__cls-9"
              data-name="Path 168"
              transform="translate(-86.568 -193.415)"
            />
            <path
              id="prefix__Path_169"
              d="M164.023 296.1a72.361 72.361 0 0 0 .456-13.3l-13.579-8.846s-14.087.7-14.892 8.838 7.058 11.926 10.255 22.308-25.811 52.9-25.811 52.9a9.605 9.605 0 0 1 2.906 2.468c26.613-24.5 34.394-48.822 40.665-64.368z"
              className="prefix__cls-8"
              data-name="Path 169"
              transform="translate(-86.568 -193.415)"
            />
            <path
              id="prefix__Path_170"
              d="M167.1 194.689s-2.534 10.376-7.314 13.03-23.468 4.939-23.468 4.939l-6.92-.821-16.527 15.5a46.689 46.689 0 0 1-10 9.608c-5.444 3.545-16.035-3.358-16.035-3.358l-4.034 4.159c-.884-.97 9.97 9.2 16.033 9.416 7.7.271 17.64-6.909 17.64-6.909l.432-.328.123.85c1.464 10.214-.555 29.62-.555 29.62 9.939-11.551 39.4-6 39.4-6-.85-14.366-9.866-41.536-9.866-41.536s13.535-4.023 19.079-7.755 8.335-18.452 8.335-18.452z"
              className="prefix__cls-4"
              data-name="Path 170"
              transform="translate(-75.382 -169.893)"
            />
            <g
              id="prefix__Group_190"
              data-name="Group 190"
              transform="translate(7.379 25.928)">
              <path
                id="prefix__Path_171"
                d="M166.281 208.911c-3.072 4.223-21.947 13.336-31.863 9.239a12.25 12.25 0 0 1-5.973-4.911l-2.489 2.334c-1.236 2.966-2.3 7.155-5.652 11.228-16.663 20.228-27.114 13.93-35.174 8.199l-2.275 3.2c.858.66 10.451 9.239 16.016 9.435 7.7.271 17.64-6.909 17.64-6.909l.432-.328.123.85c1.464 10.214-.555 29.62-.555 29.62 6.43-7.474 21.032-7.788 30.541-7.079-.648-16.523-6.8-39.747-6.8-39.747l5.8-.71s13.535-4.023 19.079-7.755 8.335-18.452 8.335-18.452l-2.676-.829c-.957 4.061-2.59 9.974-4.509 12.615z"
                className="prefix__cls-5"
                data-name="Path 171"
                transform="translate(-82.789 -196.298)"
              />
              <path
                id="prefix__Path_172"
                d="m82.81 255.888.018-.018c-.077-.059-.091-.061-.018.018z"
                data-name="Path 172"
                transform="translate(-82.762 -213.964)"
                style={{
                  fill: '#9032c6'
                }}
              />
            </g>
            <path
              id="prefix__Path_173"
              d="M153.146 203.531s-1.034 7.219.924 10.647c0 0-2.651 4.49-6.92 2.366s-4.582-10.009-4.582-10.009l6.155-6.722z"
              className="prefix__cls-17"
              data-name="Path 173"
              transform="translate(-93.131 -171.413)"
            />
            <path
              id="prefix__Path_174"
              d="M139.869 173.217s9.31.3 13 11.445-3.126 18.677-11.382 14.694-12.652-19.124-12.652-19.124z"
              className="prefix__cls-17"
              data-name="Path 174"
              transform="translate(-89.055 -163.521)"
            />
            <path
              id="prefix__Path_175"
              d="M154.494 191.734c-2.4 1-5.909 1.314-9.6-2.286-4.332-4.221-4.335-11.461-3.718-16.165a7.254 7.254 0 0 0-.657-.067l-8.575 5.452-.925 3.02.844 4.808c2.1 4.657 5.553 10.582 10.274 12.859 6.337 3.061 11.821-.676 12.357-7.621z"
              className="prefix__cls-17"
              data-name="Path 175"
              transform="translate(-89.702 -163.521)"
            />
            <path
              id="prefix__Path_176"
              d="M135.212 168.558a7.954 7.954 0 0 0-1.248-6.486c-2.257-3.189-10.65-4.384-11.7 1.591a72.767 72.767 0 0 0-1.116 10.327s-8.556.574-9.891 11.585c-.97 8 13.806 27.213 6.746 30.143s-10.065-.982-9.269-4.211c0 0-4.5 6.013-.124 11.616s23.113 3.7 28.78-9.268-.963-24.609-.963-24.609-2.826 2.151-4.5-.695 2.305-3.51 2.305-3.51-3.32-7.584-5.975-7.443c-.003.002 6.875-4.941 6.955-9.04z"
              className="prefix__cls-11"
              data-name="Path 176"
              transform="translate(-82.486 -159.429)"
            />
            <path
              id="prefix__Path_177"
              d="M200.791 191.881s-3.578-3.286-3.51-5.7 1.921-.552 3.012.188 3.715.444 5.517-1.683 5.011-4.846 5.992-1.638-1.989 9.231-4.623 10.843a9.233 9.233 0 0 0-6.388-2.01z"
              className="prefix__cls-17"
              data-name="Path 177"
              transform="translate(-109.367 -166.001)"
            />
            <path
              id="prefix__Path_178"
              d="M80.64 251.87s.279-3.022 4.1-4.478c0 0 2.173-5.39 1.57-7.357s-1.923.137-2.57 1.523-2.131 2.2-5.016 0-6.869-.935-6.423 2.977c.306 2.676 5.367 7.165 8.339 7.335z"
              className="prefix__cls-17"
              data-name="Path 178"
              transform="translate(-72.269 -183.112)"
            />
            <path
              id="prefix__Path_179"
              d="M214.981 184.147c-1.269 6.358-7.239 8.985-7.239 8.985l.039.085a9.912 9.912 0 0 1 2.5 1.437c2.55-1.554 5.4-7.233 4.7-10.507z"
              className="prefix__cls-6"
              data-name="Path 179"
              transform="translate(-112.472 -166.764)"
            />
            <path
              id="prefix__Path_180"
              d="M80.641 252.844a5.35 5.35 0 0 1 2.158-3.408c-5.032.746-8.227-4.066-9.656-6.92a3.644 3.644 0 0 0-.838 3c.302 2.669 5.363 7.16 8.336 7.328z"
              className="prefix__cls-17"
              data-name="Path 180"
              transform="translate(-72.269 -184.086)"
            />
            <path
              id="prefix__Path_181"
              d="M137.39 221.36a29.128 29.128 0 0 0 .006-22.535c-3.691 2.086-16.164 8.047-21.026-3.491a24.049 24.049 0 0 1-1.532-10.613 14.127 14.127 0 0 0-3.58 8.359c-.97 8 13.806 27.213 6.746 30.143s-10.065-.982-9.269-4.211c0 0-4.5 6.013-.124 11.616s23.112 3.704 28.779-9.268z"
              className="prefix__cls-8"
              data-name="Path 181"
              transform="translate(-82.486 -166.935)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default SubmitSuccess
