// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.copyToClipboardArea {
  padding: 20px;
  color: #1c5c85;
  display: flow-root;
  position: relative;
}
.copyToClipBoardCover {
  float: right;
}
.clipboardButton,
.clipboardButton:focus {
  margin-left: 10px;
  width: 32px;
  height: 32px;
  background-color: #f5f5f5;
  border: 0;
  outline: 0;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
}
.clipboardButton:active {
  background-color: #1c5c85;
}
.clipboardButton:active .clipboardIcon {
  color: #f5f5f5;
}
.unselectable {
  -webkit-user-select: none !important;
          user-select: none !important;
  pointer-events: none !important;
}
.clipboardIcon {
  color: #1c5c85;
}
.animate {
  animation: disappear 1s linear;
}

.copyToClipboardArea .copyAlert {
  font-size: 12px;
  opacity: 0;
  position: absolute;
  top: 5px;
  right: 20px;
}

.copyToClipboardArea_custom {
  position: relative;
}

.copyToClipboardArea_custom .copyAlert {
  font-size: 12px;
  opacity: 0;
  position: absolute;
  top: 30px;
  left: 10px;
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/common/CopyToClipboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,YAAY;AACd;AACA;;EAEE,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,SAAS;EACT,UAAU;EACV,4CAA4C;AAC9C;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,cAAc;AAChB;AACA;EACE,oCAA4B;UAA5B,4BAA4B;EAC5B,+BAA+B;AACjC;AACA;EACE,cAAc;AAChB;AACA;EACE,8BAA8B;AAChC;;AAEA;EACE,eAAe;EACf,UAAU;EACV,kBAAkB;EAClB,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,UAAU;EACV,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":[".copyToClipboardArea {\n  padding: 20px;\n  color: #1c5c85;\n  display: flow-root;\n  position: relative;\n}\n.copyToClipBoardCover {\n  float: right;\n}\n.clipboardButton,\n.clipboardButton:focus {\n  margin-left: 10px;\n  width: 32px;\n  height: 32px;\n  background-color: #f5f5f5;\n  border: 0;\n  outline: 0;\n  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);\n}\n.clipboardButton:active {\n  background-color: #1c5c85;\n}\n.clipboardButton:active .clipboardIcon {\n  color: #f5f5f5;\n}\n.unselectable {\n  user-select: none !important;\n  pointer-events: none !important;\n}\n.clipboardIcon {\n  color: #1c5c85;\n}\n.animate {\n  animation: disappear 1s linear;\n}\n\n.copyToClipboardArea .copyAlert {\n  font-size: 12px;\n  opacity: 0;\n  position: absolute;\n  top: 5px;\n  right: 20px;\n}\n\n.copyToClipboardArea_custom {\n  position: relative;\n}\n\n.copyToClipboardArea_custom .copyAlert {\n  font-size: 12px;\n  opacity: 0;\n  position: absolute;\n  top: 30px;\n  left: 10px;\n}\n\n@keyframes disappear {\n  0% {\n    opacity: 1;\n  }\n  90% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
