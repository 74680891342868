// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DesingFormTabCover {
  display: inline-block;
  background-color: #f5f5f5;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);
}

.designtabs > a {
  color: #1c5c85;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  text-decoration: none;
}

.designtabs > a:hover {
  background-color: #8A00D4;
}

.designtabs > a.selected,
.designtabs > a.selected {
  background-color: #1c5c85;
  color: #ffffff;
}

.designtabs {
  background-color: #e2e2e2;
  margin: 10px;
  display: inline-block;
}

.themeSelect {
  margin: 10px 12px 10px 16px;
  padding: 10px 10px 10px 12px;
  border-radius: 8px;
  font-family: 'Montserrat';
  border: solid 1px #ffffff;
  box-shadow: 0 3px 6px 0 rgba(3, 39, 57, 0.04);
  background-color: #ffffff;
  color: #646464;
  width: 91%;
  cursor: pointer;
}

.themeSelect:hover {
  border: solid 1px #005a87;
  color: #1c5c85;
}

.selectedTheme {
  box-shadow: 0px 0px 5px;
  background-color: #e5fbda;
}

.desingForm > .form.renderer {
  margin: unset !important;
}

.desingForm > .gleam.form.renderer {
  margin: unset !important;
}
`, "",{"version":3,"sources":["webpack://./src/modules/helper/design/DesignForm.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,yBAAyB;EACzB,4CAA4C;AAC9C;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,2BAA2B;EAC3B,4BAA4B;EAC5B,kBAAkB;EAClB,yBAAyB;EACzB,yBAAyB;EACzB,6CAA6C;EAC7C,yBAAyB;EACzB,cAAc;EACd,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;AAC3B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".DesingFormTabCover {\n  display: inline-block;\n  background-color: #f5f5f5;\n  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.08);\n}\n\n.designtabs > a {\n  color: #1c5c85;\n  display: inline-block;\n  height: 30px;\n  line-height: 30px;\n  padding: 0 10px;\n  text-decoration: none;\n}\n\n.designtabs > a:hover {\n  background-color: #8A00D4;\n}\n\n.designtabs > a.selected,\n.designtabs > a.selected {\n  background-color: #1c5c85;\n  color: #ffffff;\n}\n\n.designtabs {\n  background-color: #e2e2e2;\n  margin: 10px;\n  display: inline-block;\n}\n\n.themeSelect {\n  margin: 10px 12px 10px 16px;\n  padding: 10px 10px 10px 12px;\n  border-radius: 8px;\n  font-family: 'Montserrat';\n  border: solid 1px #ffffff;\n  box-shadow: 0 3px 6px 0 rgba(3, 39, 57, 0.04);\n  background-color: #ffffff;\n  color: #646464;\n  width: 91%;\n  cursor: pointer;\n}\n\n.themeSelect:hover {\n  border: solid 1px #005a87;\n  color: #1c5c85;\n}\n\n.selectedTheme {\n  box-shadow: 0px 0px 5px;\n  background-color: #e5fbda;\n}\n\n.desingForm > .form.renderer {\n  margin: unset !important;\n}\n\n.desingForm > .gleam.form.renderer {\n  margin: unset !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
