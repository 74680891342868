// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,
*:after,
*:before {
  box-sizing: border-box;
}

.grid {
  width: auto;
}
.grid:after {
  content: '';
  display: table;
  clear: both;
}

[class*='col-'] {
  float: left;
}
[class*='col-'] {
  padding-right: 16px;
}
[class*='col-']:last-of-type {
  padding-right: 0;
}

.col-1-16 {
  width: 6.25%;
}
.col-2-16 {
  width: 12.5%;
}
.col-3-16 {
  width: 18.75%;
}
.col-4-16 {
  width: 25%;
}
.col-5-16 {
  width: 31.25%;
}
.col-6-16 {
  width: 37.5%;
}
.col-7-16 {
  width: 43.75%;
}
.col-8-16 {
  width: 50%;
}
.col-9-16 {
  width: 56.25%;
}
.col-10-16 {
  width: 62.5%;
}
.col-11-16 {
  width: 68.75%;
}
.col-12-16 {
  width: 75%;
}
.col-13-16 {
  width: 81.25%;
}
.col-14-16 {
  width: 87.5%;
}
.col-15-16 {
  width: 93.75%;
}
.col-16-16 {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/style/grid.css"],"names":[],"mappings":"AAAA;;;EAKE,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb;AACA;EACE,WAAW;EACX,cAAc;EACd,WAAW;AACb;;AAEA;EACE,WAAW;AACb;AACA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,UAAU;AACZ;AACA;EACE,aAAa;AACf;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,UAAU;AACZ;AACA;EACE,aAAa;AACf;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,UAAU;AACZ;AACA;EACE,aAAa;AACf;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,WAAW;AACb","sourcesContent":["*,\n*:after,\n*:before {\n  -webkit-box-sizing: border-box;\n  -moz-box-sizing: border-box;\n  box-sizing: border-box;\n}\n\n.grid {\n  width: auto;\n}\n.grid:after {\n  content: '';\n  display: table;\n  clear: both;\n}\n\n[class*='col-'] {\n  float: left;\n}\n[class*='col-'] {\n  padding-right: 16px;\n}\n[class*='col-']:last-of-type {\n  padding-right: 0;\n}\n\n.col-1-16 {\n  width: 6.25%;\n}\n.col-2-16 {\n  width: 12.5%;\n}\n.col-3-16 {\n  width: 18.75%;\n}\n.col-4-16 {\n  width: 25%;\n}\n.col-5-16 {\n  width: 31.25%;\n}\n.col-6-16 {\n  width: 37.5%;\n}\n.col-7-16 {\n  width: 43.75%;\n}\n.col-8-16 {\n  width: 50%;\n}\n.col-9-16 {\n  width: 56.25%;\n}\n.col-10-16 {\n  width: 62.5%;\n}\n.col-11-16 {\n  width: 68.75%;\n}\n.col-12-16 {\n  width: 75%;\n}\n.col-13-16 {\n  width: 81.25%;\n}\n.col-14-16 {\n  width: 87.5%;\n}\n.col-15-16 {\n  width: 93.75%;\n}\n.col-16-16 {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
