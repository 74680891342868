// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.elementPhone > .input {
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
}

.form.platform-win .elementPhone {
  font-family: 'Twemoji Country Flags', 'Montserrat', sans-serif !important;
}

.infernal.form .elementPhone {
  font-family: 'Twemoji Country Flags', 'Montserrat', sans-serif !important;
}

.elementPhone > .input > .dialCode {
  width: 3.1rem;
  font-size: x-large;
  border: solid 1px #c9c9c9;
  border-radius: 3px;
  outline: 0;
  background-color: #f5f5f5;
  opacity: 1;
  color: #333;
}

.elementPhone > .input > .dialCode:hover,
.elementPhone > .input > .dialCode:focus {
  width: 12rem;
  font-size: medium;
}

.elementPhone > .input > .phoneInput {
  width: 100%;
  padding: 5px 16px;
  height: 42px;
  border: solid 1px #c9c9c9;
  border-radius: 3px;
  outline: 0;
  background-color: #f5f5f5;
  box-sizing: border-box;
  margin-left: 5px;
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/modules/elements/Phone.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,yEAAyE;AAC3E;;AAEA;EACE,yEAAyE;AAC3E;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,UAAU;EACV,yBAAyB;EACzB,UAAU;EACV,WAAW;AACb;;AAEA;;EAEE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,UAAU;EACV,yBAAyB;EACzB,sBAAsB;EACtB,gBAAgB;EAChB,WAAW;AACb","sourcesContent":[".elementPhone > .input {\n  display: flex;\n  width: 100%;\n  margin-top: 0.5rem;\n}\n\n.form.platform-win .elementPhone {\n  font-family: 'Twemoji Country Flags', 'Montserrat', sans-serif !important;\n}\n\n.infernal.form .elementPhone {\n  font-family: 'Twemoji Country Flags', 'Montserrat', sans-serif !important;\n}\n\n.elementPhone > .input > .dialCode {\n  width: 3.1rem;\n  font-size: x-large;\n  border: solid 1px #c9c9c9;\n  border-radius: 3px;\n  outline: 0;\n  background-color: #f5f5f5;\n  opacity: 1;\n  color: #333;\n}\n\n.elementPhone > .input > .dialCode:hover,\n.elementPhone > .input > .dialCode:focus {\n  width: 12rem;\n  font-size: medium;\n}\n\n.elementPhone > .input > .phoneInput {\n  width: 100%;\n  padding: 5px 16px;\n  height: 42px;\n  border: solid 1px #c9c9c9;\n  border-radius: 3px;\n  outline: 0;\n  background-color: #f5f5f5;\n  box-sizing: border-box;\n  margin-left: 5px;\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
