// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.builderStage .form {
  width: 750px;
  margin: 0 auto;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}

.builderStage .form > .element.requiredError {
  border: 1px solid red;
  background: rgba(255, 0, 0, 0.3);
}

.form .element.requiredError .metadata .requiredErrorText {
  display: block;
  font-size: 14px;
  color: #646464;
  margin-top: 10px;
  word-break: break-word;
}

.form > .element > .label {
  min-width: 200px;
}

.form > .element > .metadata {
  width: 100%;
}

.form .element .metadata .requiredErrorText {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/modules/elements/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,0CAA0C;AAC5C;;AAEA;EACE,qBAAqB;EACrB,gCAAgC;AAClC;;AAEA;EACE,cAAc;EACd,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;AACf","sourcesContent":[".builderStage .form {\n  width: 750px;\n  margin: 0 auto;\n  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);\n}\n\n.builderStage .form > .element.requiredError {\n  border: 1px solid red;\n  background: rgba(255, 0, 0, 0.3);\n}\n\n.form .element.requiredError .metadata .requiredErrorText {\n  display: block;\n  font-size: 14px;\n  color: #646464;\n  margin-top: 10px;\n  word-break: break-word;\n}\n\n.form > .element > .label {\n  min-width: 200px;\n}\n\n.form > .element > .metadata {\n  width: 100%;\n}\n\n.form .element .metadata .requiredErrorText {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
