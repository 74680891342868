// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header > .mainTabs > a {
  color: #1c5c85;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  text-decoration: none;
}

.adminpage {
  margin-top: 80px;
}

.header > .mainTabs > a:hover {
  background-color: #8A00D4;
}

.header > .mainTabs > a.selected,
.header > .mainTabs > a.selected {
  background-color: #1c5c85;
  color: #ffffff;
}

.mainTabs {
  background-color: #e2e2e2;
}
`, "",{"version":3,"sources":["webpack://./src/modules/admin/AdminPage.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,qBAAqB;EACrB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".header > .mainTabs > a {\n  color: #1c5c85;\n  display: inline-block;\n  height: 30px;\n  line-height: 30px;\n  padding: 0 10px;\n  text-decoration: none;\n}\n\n.adminpage {\n  margin-top: 80px;\n}\n\n.header > .mainTabs > a:hover {\n  background-color: #8A00D4;\n}\n\n.header > .mainTabs > a.selected,\n.header > .mainTabs > a.selected {\n  background-color: #1c5c85;\n  color: #ffffff;\n}\n\n.mainTabs {\n  background-color: #e2e2e2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
