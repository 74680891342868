// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.integration-wrapper
  > .integration-content
  > .integration-configuration
  > .integration-inputs
  > .webhookUrl
  > .elementTextBox
  > .input
  > input {
  width: 700px;
}

.integration-wrapper
  > .integration-content
  > .integration-configuration
  > .integration-inputs
  > .invalid-url
  > .elementTextBox
  > .input
  > input {
  border-radius: 5px;
  border: 2px solid red;
}

.integration-wrapper
  > .integration-content
  > .integration-configuration
  > .integration-inputs
  > .field-limit-reached {
  border: 2px solid red;
}
`, "",{"version":3,"sources":["webpack://./src/modules/integrations/Discord.css"],"names":[],"mappings":"AAAA;;;;;;;;EAQE,YAAY;AACd;;AAEA;;;;;;;;EAQE,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;;;;;EAKE,qBAAqB;AACvB","sourcesContent":[".integration-wrapper\n  > .integration-content\n  > .integration-configuration\n  > .integration-inputs\n  > .webhookUrl\n  > .elementTextBox\n  > .input\n  > input {\n  width: 700px;\n}\n\n.integration-wrapper\n  > .integration-content\n  > .integration-configuration\n  > .integration-inputs\n  > .invalid-url\n  > .elementTextBox\n  > .input\n  > input {\n  border-radius: 5px;\n  border: 2px solid red;\n}\n\n.integration-wrapper\n  > .integration-content\n  > .integration-configuration\n  > .integration-inputs\n  > .field-limit-reached {\n  border: 2px solid red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
