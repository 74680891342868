// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.universalEditableList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.universalEditableList > .builderAddNewButton.addNewItem {
  display: block;
  background: #8A00D4;
  padding: 0.5rem;
  color: white;
  border: 1px solid whitesmoke;
  cursor: pointer;
  margin-top: 3px;
}
.universalEditableList > .builderAddNewButton.addNewItem:hover {
  background: #F12796;
}

.universalEditableList > .input {
  display: block;
  width: 100%;
}
.universalEditableList > .input > .label > span {
  border: solid 1px #c9c9c9;
  padding: 3px;
  width: 82%;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  height: 2rem;
  float: left;
  background: white;
  margin: 3px 0 3px 0;
}

.universalEditableList > .fl.input .action {
  display: flex;
  opacity: 0.62;
  width: 18%;
  padding: 10px 3px 10px 3px;
  font-size: 10pt;
}
.universalEditableList > .fl.input .action > .popover-container {
  padding: 2px;
}

.universalEditableList > .fl.input li:hover .action,
.universalEditableList > .fl.input:hover .label + .action {
  opacity: 1;
}

.fl.input li .action:hover,
.fl.input .universalEditableList + .action:hover {
  opacity: 1;
}
`, "",{"version":3,"sources":["webpack://./src/modules/internal/List.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,YAAY;AACd;AACA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;EACf,YAAY;EACZ,4BAA4B;EAC5B,eAAe;EACf,eAAe;AACjB;AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,YAAY;EACZ,UAAU;EACV,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,UAAU;EACV,0BAA0B;EAC1B,eAAe;AACjB;AACA;EACE,YAAY;AACd;;AAEA;;EAEE,UAAU;AACZ;;AAEA;;EAEE,UAAU;AACZ","sourcesContent":[".universalEditableList {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  height: 100%;\n}\n.universalEditableList > .builderAddNewButton.addNewItem {\n  display: block;\n  background: #8A00D4;\n  padding: 0.5rem;\n  color: white;\n  border: 1px solid whitesmoke;\n  cursor: pointer;\n  margin-top: 3px;\n}\n.universalEditableList > .builderAddNewButton.addNewItem:hover {\n  background: #F12796;\n}\n\n.universalEditableList > .input {\n  display: block;\n  width: 100%;\n}\n.universalEditableList > .input > .label > span {\n  border: solid 1px #c9c9c9;\n  padding: 3px;\n  width: 82%;\n  display: block;\n  overflow: hidden;\n  white-space: nowrap;\n  height: 2rem;\n  float: left;\n  background: white;\n  margin: 3px 0 3px 0;\n}\n\n.universalEditableList > .fl.input .action {\n  display: flex;\n  opacity: 0.62;\n  width: 18%;\n  padding: 10px 3px 10px 3px;\n  font-size: 10pt;\n}\n.universalEditableList > .fl.input .action > .popover-container {\n  padding: 2px;\n}\n\n.universalEditableList > .fl.input li:hover .action,\n.universalEditableList > .fl.input:hover .label + .action {\n  opacity: 1;\n}\n\n.fl.input li .action:hover,\n.fl.input .universalEditableList + .action:hover {\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
