// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer.footer .col-8-16 {
  text-align: center;
}

footer.footer {
  color: #1c5c85;
  font-size: 18px;
  margin-top: 50px;
  padding-bottom: 40px;
  width: 100%;
}

footer.footer a {
  text-decoration: none;
  color: inherit;
}

@media only screen and (max-width: 767px) {
  footer.footer .col-8-16 {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }
  footer.footer {
    margin-top: 25px;
    padding-bottom: 0px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/modules/helper/Footer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,WAAW;AACb;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;EACE;IACE,kBAAkB;IAClB,WAAW;IACX,mBAAmB;EACrB;EACA;IACE,gBAAgB;IAChB,mBAAmB;EACrB;AACF","sourcesContent":["footer.footer .col-8-16 {\n  text-align: center;\n}\n\nfooter.footer {\n  color: #1c5c85;\n  font-size: 18px;\n  margin-top: 50px;\n  padding-bottom: 40px;\n  width: 100%;\n}\n\nfooter.footer a {\n  text-decoration: none;\n  color: inherit;\n}\n\n@media only screen and (max-width: 767px) {\n  footer.footer .col-8-16 {\n    text-align: center;\n    width: 100%;\n    margin-bottom: 20px;\n  }\n  footer.footer {\n    margin-top: 25px;\n    padding-bottom: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
