// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `select.dropdown-select {
  box-shadow: none;
  border-style: solid;
  background: #ffffff;
  flex: 1 1;
  padding: 2px;
  color: #0071bc;
  cursor: pointer;
}
select::-ms-expand {
  display: none;
}

.adminpage .elementDropdown > div > .dropdown-div {
  overflow: visible !important;
}

.elementDropdown > div > .dropdown-div > select {
  font-family: 'Twemoji Country Flags', 'Montserrat', sans-serif !important;
}

.dropdown-div {
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 3em;
  line-height: 3;
  overflow: hidden;
}

#edit-preview-button {
  background-color: white;
  color: black;
  border: 2px solid #0071bc;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  margin: 8px 4px;
  transition-duration: 0.4s;
  cursor: pointer;
}

#edit-preview-button:hover {
  background-color: #737679;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/modules/elements/Dropdown.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,SAAO;EACP,YAAY;EACZ,cAAc;EACd,eAAe;AACjB;AACA;EACE,aAAa;AACf;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,yEAAyE;AAC3E;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,WAAW;EACX,WAAW;EACX,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;EACf,eAAe;EACf,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd","sourcesContent":["select.dropdown-select {\n  box-shadow: none;\n  border-style: solid;\n  background: #ffffff;\n  flex: 1;\n  padding: 2px;\n  color: #0071bc;\n  cursor: pointer;\n}\nselect::-ms-expand {\n  display: none;\n}\n\n.adminpage .elementDropdown > div > .dropdown-div {\n  overflow: visible !important;\n}\n\n.elementDropdown > div > .dropdown-div > select {\n  font-family: 'Twemoji Country Flags', 'Montserrat', sans-serif !important;\n}\n\n.dropdown-div {\n  position: relative;\n  display: inline-flex;\n  width: 100%;\n  height: 3em;\n  line-height: 3;\n  overflow: hidden;\n}\n\n#edit-preview-button {\n  background-color: white;\n  color: black;\n  border: 2px solid #0071bc;\n  padding: 10px 20px;\n  text-align: center;\n  display: inline-block;\n  font-size: 12px;\n  margin: 8px 4px;\n  transition-duration: 0.4s;\n  cursor: pointer;\n}\n\n#edit-preview-button:hover {\n  background-color: #737679;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
