// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.latlong-input {
  box-sizing: border-box;
  height: 25px;
  padding: 5px 16px;
  color: #333333;
  border: solid 1px #c9c9c9;
  border-radius: 3px;
  outline: 0;
  background-color: #f5f5f5;
}

.latlong-label {
  width: 100%;
}

.latlong-button {
}
`, "",{"version":3,"sources":["webpack://./src/modules/elements/Location.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,YAAY;EACZ,iBAAiB;EACjB,cAAc;EACd,yBAAyB;EACzB,kBAAkB;EAClB,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb;;AAEA;AACA","sourcesContent":[".latlong-input {\n  box-sizing: border-box;\n  height: 25px;\n  padding: 5px 16px;\n  color: #333333;\n  border: solid 1px #c9c9c9;\n  border-radius: 3px;\n  outline: 0;\n  background-color: #f5f5f5;\n}\n\n.latlong-label {\n  width: 100%;\n}\n\n.latlong-button {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
