// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reset-success {
  width: 100%;
  padding: 20px;
  text-align: center;
  font-family: Montserrat;
  line-height: 25px;
  color: #1c5c85;
}
.reset-email {
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/modules/helper/ForgotPassword.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,uBAAuB;EACvB,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".reset-success {\n  width: 100%;\n  padding: 20px;\n  text-align: center;\n  font-family: Montserrat;\n  line-height: 25px;\n  color: #1c5c85;\n}\n.reset-email {\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
