// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  width: 90px;
}
input[type='file'] {
  display: none;
}

.elementFileUpload > .inputContainer > .fileDisplay {
  word-break: break-all;
}

.builder .elementFileUpload .inputContainer label {
  pointer-events: none !important;
}
`, "",{"version":3,"sources":["webpack://./src/modules/elements/FileUpload.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,qBAAqB;EACrB,iBAAiB;EACjB,eAAe;EACf,WAAW;AACb;AACA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,+BAA+B;AACjC","sourcesContent":[".custom-file-upload {\n  border: 1px solid #ccc;\n  display: inline-block;\n  padding: 6px 12px;\n  cursor: pointer;\n  width: 90px;\n}\ninput[type='file'] {\n  display: none;\n}\n\n.elementFileUpload > .inputContainer > .fileDisplay {\n  word-break: break-all;\n}\n\n.builder .elementFileUpload .inputContainer label {\n  pointer-events: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
