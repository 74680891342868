// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#edit-preview-button {
  background-color: white;
  color: black;
  border: 2px solid #0071bc;
  padding: 10px 20px;
  text-align: center;
  display: inline-block;
  font-size: 12px;
  margin: 8px 4px;
  transition-duration: 0.4s;
  cursor: pointer;
}

#edit-preview-button:hover {
  background-color: #737679;
  color: white;
}

.elementRadio
  > .metadata
  > details
  > summary
  > .popover-container:hover
  > .popoverText {
  width: 12rem;
  height: fit-content;
  top: -45px;
  left: -165px;
  word-break: break-word;
}

.elementRadio > .metadata.answerExplanationContainer {
  margin-top: 2rem;
  padding: 0.5rem;
}

.elementRadio .unselect-button {
  background-color: #8A00D4;
  outline: 0;
  border: 0;
  border-radius: 0;
  padding: 0.25rem;
  width: 9rem;
  min-height: 2.5rem;
  color: white;
  cursor: pointer;
  word-wrap: break-word;
  float: right;
}

.elementRadio .unselect-button:hover {
  background-color: #5e8c9e;
}

.builderStage .unselect-button {
  visibility: visible !important;
}

.elementRadio .unselect-button > .unselect-label > span {
  color: white !important;
  margin-top: unset !important;
  font-size: 11pt;
}

.answerExplanationLabel {
  transform: translate(12px, 21px);
  max-width: 580px !important;
}
`, "",{"version":3,"sources":["webpack://./src/modules/elements/Radio.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,eAAe;EACf,eAAe;EACf,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;;;;;;EAME,YAAY;EACZ,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,UAAU;EACV,SAAS;EACT,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,uBAAuB;EACvB,4BAA4B;EAC5B,eAAe;AACjB;;AAEA;EACE,gCAAgC;EAChC,2BAA2B;AAC7B","sourcesContent":["#edit-preview-button {\n  background-color: white;\n  color: black;\n  border: 2px solid #0071bc;\n  padding: 10px 20px;\n  text-align: center;\n  display: inline-block;\n  font-size: 12px;\n  margin: 8px 4px;\n  transition-duration: 0.4s;\n  cursor: pointer;\n}\n\n#edit-preview-button:hover {\n  background-color: #737679;\n  color: white;\n}\n\n.elementRadio\n  > .metadata\n  > details\n  > summary\n  > .popover-container:hover\n  > .popoverText {\n  width: 12rem;\n  height: fit-content;\n  top: -45px;\n  left: -165px;\n  word-break: break-word;\n}\n\n.elementRadio > .metadata.answerExplanationContainer {\n  margin-top: 2rem;\n  padding: 0.5rem;\n}\n\n.elementRadio .unselect-button {\n  background-color: #8A00D4;\n  outline: 0;\n  border: 0;\n  border-radius: 0;\n  padding: 0.25rem;\n  width: 9rem;\n  min-height: 2.5rem;\n  color: white;\n  cursor: pointer;\n  word-wrap: break-word;\n  float: right;\n}\n\n.elementRadio .unselect-button:hover {\n  background-color: #5e8c9e;\n}\n\n.builderStage .unselect-button {\n  visibility: visible !important;\n}\n\n.elementRadio .unselect-button > .unselect-label > span {\n  color: white !important;\n  margin-top: unset !important;\n  font-size: 11pt;\n}\n\n.answerExplanationLabel {\n  transform: translate(12px, 21px);\n  max-width: 580px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
