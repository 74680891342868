// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.elementTextArea textarea {
  width: 100%;
  height: 100px;
  border: solid 1px #c9c9c9;
  background-color: #ffffff;
  resize: none;
}

.elementTextArea textarea:focus-visible {
  outline-color: #c9c9c9;
}
`, "",{"version":3,"sources":["webpack://./src/modules/elements/TextArea.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,yBAAyB;EACzB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".elementTextArea textarea {\n  width: 100%;\n  height: 100px;\n  border: solid 1px #c9c9c9;\n  background-color: #ffffff;\n  resize: none;\n}\n\n.elementTextArea textarea:focus-visible {\n  outline-color: #c9c9c9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
