// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formPermissionShare .permissionRow {
  margin: 10px;
  border-bottom: 1px dotted black;
}

.formPermissionShare th {
  padding: 15px;
}

.formPermissionShare .perm {
  width: 50px;
  text-align: center;
}

.formPermissionShare td.perm {
  color: #ccc;
}

.formPermissionShare table {
  padding: 20px;
  width: 750px;
}

.invite-status {
  text-align: center;
}

.formPermissionShare .perm.active {
  color: green;
}

.formPermissionShare .actions {
  text-align: center;
}

.confirmDeletePerm {
  margin: 20px;
}

.formPermissionShare .actions span {
  color: rgb(225, 225, 225);
  margin-right: 5px;
  padding: 10px;
}

.formPermissionShare .actions span:hover {
  color: rgb(48, 93, 134);
  cursor: pointer;
}

.formPermissionShare {
  padding: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/modules/common/FormPermissionShare.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,+BAA+B;AACjC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".formPermissionShare .permissionRow {\n  margin: 10px;\n  border-bottom: 1px dotted black;\n}\n\n.formPermissionShare th {\n  padding: 15px;\n}\n\n.formPermissionShare .perm {\n  width: 50px;\n  text-align: center;\n}\n\n.formPermissionShare td.perm {\n  color: #ccc;\n}\n\n.formPermissionShare table {\n  padding: 20px;\n  width: 750px;\n}\n\n.invite-status {\n  text-align: center;\n}\n\n.formPermissionShare .perm.active {\n  color: green;\n}\n\n.formPermissionShare .actions {\n  text-align: center;\n}\n\n.confirmDeletePerm {\n  margin: 20px;\n}\n\n.formPermissionShare .actions span {\n  color: rgb(225, 225, 225);\n  margin-right: 5px;\n  padding: 10px;\n}\n\n.formPermissionShare .actions span:hover {\n  color: rgb(48, 93, 134);\n  cursor: pointer;\n}\n\n.formPermissionShare {\n  padding: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
